import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import  { Component, Prop, Watch } from 'vue-property-decorator'
import {mapState} from "vuex";
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'

interface Survey {
  survey_type: string;
  filled: boolean;
  session: any;
  fields?: any[];
}

interface Surveys {
  sessions_attended_without_surveys: Survey[];
  general: Survey[];
  keynote: Survey[];
  sessions: Survey[];
}

@Component({
  name: 'GtrSurveysIndex',
  computed: {
    ...mapState('surveys', ['surveys', 'sessionCredits', 'certificates'])
  }
})
export default class GtrSurveysIndex extends GtrSuper {

  @Prop()
  allContent: any

  @Prop()
  design: any

  @Prop()
  participant: any

  surveys!: Surveys;
  certificates!: Record<string, any>;
  sessionCredits!: Record<string, any>;

  data() {
    return {
      loading: true,
      currentTab: 0,
      allSurveys: {
        incomplete: [] as Survey[],
        complete: [] as Survey[],
        sessions_attended_without_surveys: [] as Survey[]
      },
      certificateUrl: null,
      transcriptUrl: null
    }
  }

  @Watch('allContent')
  async onAllContentChange (payload: any) {
    if (payload) {
      this.fetchAll()
    }
  }

  @Watch('surveys')
  categorizeSurveys (payload: Surveys) {
    if (payload) {
      const { sessions_attended_without_surveys = [] } = payload
      const allSurveys = this.$data.allSurveys
      allSurveys.incomplete = []
      allSurveys.complete = []
      allSurveys.sessions_attended_without_surveys.push(...sessions_attended_without_surveys)
      if (payload.general?.[0]) {
        if (payload.general[0].fields?.length) {
          allSurveys[payload.general[0].filled ? 'complete' : 'incomplete'].push(payload.general[0])
        }
      }
      if (payload.keynote?.[0]) {
        allSurveys[payload.keynote[0].filled ? 'complete' : 'incomplete'].push(payload.keynote[0])
      }
      if (payload.sessions?.length) {
        payload.sessions.forEach((survey: Survey) => {
          allSurveys[survey.filled ? 'complete' : 'incomplete'].push(survey)
        })
      }
      if (allSurveys.incomplete.length === 0) {
        this.$data.currentTab = 1
      }
    }
  }

  async mounted() {
    try {
      if (this.$route.params.login_key) {
        this.$store.dispatch('common/showLoader', { value: true })
        const payload: any = {
          event_identifier: this.$route.params.event_identifier,
          pagenum: this.$route.params.pagenum,
          isDev: this.isDev,
          form: 'surveys',
          data: {
            login_key: this.$route.params.login_key,
          }
        }
        await this.$store.dispatch('auth/login', payload)
        Container.get(Notification).success('You have successfully logged in.')
        this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier + '/surveys/dashboard')
      }
      this.fetchAll()
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  private async fetchAll () {
    this.$store.dispatch('common/showLoader', { value: true })
    const event_identifier = this.$route.params.event_identifier
    if (event_identifier){
      await this.$store.dispatch('auth/getParticipant', {
        event_identifier: event_identifier
      })
      if (Object.keys(this.allContent).length > 0) {
        await this.fetchSurveys()
        await this.fetchSurveyCredits()
        const certificateData = await this.fetchCertificates()
        const certificates = certificateData?.certificates
        if (certificates?.length) {
          const activeCertificate = certificates.find(certificate => certificate.active === 1)
          if (activeCertificate?.uuid) {
            this.getCertificateAndTranscript(activeCertificate.uuid)
          }
        }
        this.$data.loading = false
      }
    }
    this.$store.dispatch('common/hideLoader')
  }

  private async fetchSurveys() {
    try {
      const event_identifier = this.$route.params.event_identifier
      const event_uuid = this.allContent.data.event.uuid
      const participant_uuid = this.$store.state.auth.participant.uuid
      if (event_identifier && participant_uuid) {
        const payload: any = {
          event_uuid,
          participant_uuid,
          event_identifier
        }
        await this.$store.dispatch('surveys/getSurveys', payload)
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchSurveyCredits (): Promise<any> {
    try {
      const payload = {
        event_uuid: this.allContent.data.event.uuid
      }
      const response = await this.$store.dispatch('surveys/getSurveyCredits', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCertificates () {
    try {
      const payload = {
        event_uuid: this.allContent.data.event.uuid
      }
      const response = await this.$store.dispatch('surveys/getCertificates', payload)
      return response.data
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get cols () {
    return this.$vuetify.breakpoint.smAndUp ? '3' : '4';
  }

  async getCertificateAndTranscript (certificateUuid: string) {
    try {
      const payload = {
        event_uuid: this.allContent.data.event.uuid,
        certificate_uuid: certificateUuid
      }
      const response = await this.$store.dispatch('surveys/getCertificateExport', payload)
      this.$data.certificateUrl = response.data.certificate
      this.$data.transcriptUrl = response.data.transcript
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get displayCertificateBtn () {
    return this.allContent?.data?.evals_settings?.show_download_certificate_button ?? true
  }

  get certificateBtnVerbiage () {
    return this.allContent?.data?.evals_settings?.download_certificate_button_verbiage["en"] ?? 'Certificate'
  }

  get displayTranscriptBtn () {
    return this.allContent?.data?.evals_settings?.show_download_transcript_button ?? true
  }

  get transcriptBtnVerbiage () {
    return this.allContent?.data?.evals_settings?.download_transcript_button_verbiage["en"] ?? 'Transcript'
  }

  get displayCredits () {
    return this.allContent?.data?.evals_settings?.show_credits_in_portal ?? true
  }

  get totalCredits () {
    return this.sessionCredits['Credits'] ?? ''
  }

  get totalAltCredits () {
    return this.sessionCredits['Alt credits'] ?? ''
  }

  get creditsName () {
    return this.allContent?.data?.track_settings?.credits_name ?? 'Credits'
  }

  get altCreditsName () {
    return this.allContent?.data?.track_settings?.alt_credits_name ?? 'Alt Credits'
  }

  // displayCertificateAfterDate is a computed property that returns true if the current date is after the show_certificate_after setting.
  get displayCertificateAfterDate () {
    // store the current date in a constant
    const currentDate = new Date();

    // Get the timezone of the event from allContent
    const eventTimezone = this.allContent?.data?.event?.timezone;

    // create a new date object from the show_certificate_after setting
    const showCertificateAfter = new Date(this.allContent?.data?.evals_settings?.show_certificate_after);

    // If eventTimezone is defined, convert showCertificateAfter to event timezone
    if (eventTimezone) {
      const eventDate = new Date(showCertificateAfter.toLocaleString('en-US', { timeZone: eventTimezone }));
      const eventOffset = eventDate.getTimezoneOffset();
      showCertificateAfter.setMinutes(showCertificateAfter.getMinutes() - eventOffset);
    }

    // if the show_certificate_after setting is not set, or is an invalid date, return true
    if (isNaN(showCertificateAfter.getTime())) return true;

    // lastly, return true if the current date is after or the same as the show_certificate_after setting
    return currentDate >= showCertificateAfter;
  }


  get displayCertificateAndTranscriptButtons () {
    return this.activeCertificateExists && this.displayCertificateAfterDate
  }

  get surveysRequired() {
    return this.allContent?.data?.evals_settings?.surveys_required ?? true
  }

  get headingTextColor (): string {
    return this.design?.default_heading_text_color || 'var(--teal-300)'
  }

  get primaryButtonColor (): string {
    return this.design?.default_primary_button_color || 'var(--teal-300)'
  }

  get eventTitle () {
    return this.allContent?.data?.event.name ?? ''
  }

  surveyName (survey: Survey): string {
    switch (survey.survey_type) {
      case 'general':
        return 'General Conference Feedback'
      case 'keynote':
        return 'Keynote Session'
      case 'session':
        return survey.session.name
      default:
        return ''
    }
  }

  get surveyInfo () {
    let totalSurveys = 0
    let completedSurveys = 0
    let percentage = 0
    const creditsAccrued = {}
    if (this.surveys && typeof this.surveys === 'object') {
      for (const type in this.surveys) {
        if (type === 'general' && !(this.surveys.general[0].fields || []).length) {
          continue
        }
        totalSurveys += this.surveys[type].length
        this.surveys[type].forEach((survey: Survey) => {
          if (survey.filled) {
            completedSurveys++
            if (type === 'sessions') {
              let creditsName = survey.session.credits_name ?? 'Credits'
              creditsAccrued[creditsName] = (creditsAccrued[creditsName] ?? 0) + Number(survey.session.credits)
              creditsName = survey.session.alt_credits_name ?? 'Alt Credits'
              creditsAccrued[creditsName] = (creditsAccrued[creditsName] ?? 0) + Number(survey.session.alt_credits)
            }
          }
        })
      }
      percentage = Math.round(completedSurveys / totalSurveys * 100)
    }
    return {
      numberOfSurveys: totalSurveys,
      percentComplete: isNaN(percentage) ? '' : percentage,
      creditsAccrued: creditsAccrued
    }
  }

  renderSessionCreditsName (survey: any, type: string): string {
    const credits = survey.session?.[type] ?? 0
    const credits_name = this.allContent.data?.track_settings?.[type + '_name'] ?? this.$options?.filters?.capitalizeAndRemoveUnderscores(type)
    return credits == 1 && credits_name.charAt(credits_name.length - 1) === 's' ? credits_name.substr(0, credits_name.length - 1) : credits_name
  }

  get activeCertificateExists (): boolean {
    return this.certificates?.certificates?.some(certificate => certificate.active === 1) ?? false
  }

  goToSurvey (survey: Survey) {
    this.$router.push({
      name: 'surveys.session',
      params: {
        event_identifier: this.$route.params.event_identifier,
        session_uuid: survey.survey_type === 'session' ? survey.session.uuid : survey.survey_type
      }
    })
  }
}
