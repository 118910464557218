import Container from 'typedi'
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import PaymentService from '../services/payment.service'
import ErrorHandlerService from '../services/error-handler.service';


interface ITrackedParticipant {
    name?: string;
    lineItems: Record<string, any>[];
    parent: boolean;
}

interface IParticipantTracker {
    [uuid: string]: ITrackedParticipant;
}

@Module({
    namespaced: true
})
export default class PaymentStore extends VuexModule {
    payment_form: any[] = []
	fees: any = {}
	groupFees: any = {}
	payflow_token: any[] = []
	stripe_token: any[] = []
    payment_properties: any [] = []
    participantTracker: IParticipantTracker = {};
    subtotal = 0
    taxesTotal = 0;
    totalPromoCodeDiscount = 0;
    transactionTotal = 0;
    balance = 0;

    @Mutation
    SET_PAYMENT_FORM(payload: any) {
		this.payment_form = payload
    }

    // TODO:
    // Revisit later to confirm if safe to delete.
    //
    // @Mutation
    // SET_PARTICIPANT_TRACKER(payload: {
    //     uuid: string;
    //     parent: boolean;
    // }): void {

    //     // unpack arguments.
    //     const { uuid, parent } = payload;

    //     // set the participant id as key, initialize as empty array.
    //     const newParticipant: Record<string, any> = {};

    //     // set our new object to participant uuid.
    //     // tried doing it directly, but was getting interpreted as 'uuid'.
    //     newParticipant[uuid] = {
    //         name: 'Unnamed Participant',
    //         lineItems: [],
    //         parent: parent
    //     };

    //     // TODO: Move this to update.

    //     this.participantTracker = {
    //         ...this.participantTracker,
    //         ...newParticipant
    //     };
    // }

    @Mutation
    UPDATE_PARTICIPANT_TRACKER(payload: {
        uuid: string;
        name: string;
        line_items: Array<Record<string, any>>;
        parent: boolean;
        full_participant: any;
        discount_total: any;
        subtotal: any;
        balance: any;
    }): void {
        // update a participants line_items.
        const {
            uuid,
            name,
            line_items,
            parent,
            full_participant,
            discount_total,
            subtotal,
            balance
        } = payload;

        const newParticipantObj: Record<string, any> = {};

        newParticipantObj[uuid] = {
            name: name,
            lineItems: line_items,
            parent: parent,
            full_participant: full_participant,
            discount_total,
            subtotal,
            balance
        };

        // Rebuild participantTracker. This is to maintain reactivity in Vue.
        // https://vuex.vuejs.org/guide/mutations.html#object-style-commit
        this.participantTracker = {
            ...this.participantTracker,
            ...newParticipantObj
        };
    }

    @Mutation
    UPDATE_REG_TOTALS(payload: any): void {
        this.subtotal = payload.subtotal;
        this.taxesTotal = payload.tax;
        this.totalPromoCodeDiscount = payload.discounted_total;
        this.transactionTotal = payload.total;
        this.balance = payload.total;
    }

    @Mutation
	SET_FEES(payload: any) {
		this.fees = payload
    }

    @Mutation
	SET_GROUP_FEES(payload: any) {
		this.groupFees = payload
    }

    @Mutation
	SET_PAYFLOW_TOKEN(payload: any) {
		this.payflow_token = payload
    }

    @Mutation
	SET_PAYMENT_PROPERTIES(payload: any) {
		this.payment_properties = payload
    }

    @Mutation
	SET_STRIPE_TOKEN(payload: any) {
		this.stripe_token = payload
    }

    @Action({ rawError: true })
    async getPaymentProperties (payload: any) {
        const { event_identifier, widget } = payload
        const response = await Container.get(PaymentService).getPaymentProperties(event_identifier, widget)
        this.context.commit('SET_PAYMENT_PROPERTIES', response.data)
        return response
    }

    @Action({ rawError: true })
    async startPayflowToken (payload: any) {
        const { event_identifier, widget } = payload
        const response = await Container.get(PaymentService).startPayFlowToken(event_identifier, widget)
        this.context.commit('SET_PAYFLOW_TOKEN', response.data)
        return response
    }

    @Action({ rawError: true })
    async startStripeToken (payload: any) {
        const { event_identifier, widget } = payload
        const response = await Container.get(PaymentService).startStripeToken(event_identifier, widget)
        this.context.commit('SET_STRIPE_TOKEN', response.data)
        return response
    }

    @Action({ rawError: true })
    async startStripeTokenLeads (payload: any) {
        const { event_identifier, widget } = payload
        const response = await Container.get(PaymentService).startStripeTokenLeads(event_identifier, widget)
        this.context.commit('SET_STRIPE_TOKEN', response.data)
        return response
    }

    @Action({ rawError: true })
    async getFees(payload: {
        event_identifier: string;
        participant_uuid: string;
        participant_name: string;
    }) {
        const { event_identifier,participant_uuid, participant_name } = payload;
        const response = await Container.get(PaymentService).getFees(event_identifier, participant_uuid)
        this.context.commit('SET_FEES', response.data) // leaving this for backwards compatibility.

        // update participant if they are tracked, create participant if not.
        if (participant_uuid in this.participantTracker) {
            this.context.commit('UPDATE_PARTICIPANT_TRACKER',
                {
                    uuid: participant_uuid, // paticipants uuid.
                    name: participant_name, // participants name.
                    line_items: response.data.line_items, // add new line_items.
                    parent: this.participantTracker[participant_uuid].parent, // use the current objects parent value.
                    discount_total: response.data.discount_total,
                    subtotal: response.data.subtotal,
                    balance: response.data.balance
                }
            );

        } else {
            const line_items = this.fees.line_items.length === 0 ? [] : this.fees.line_items;

            if(Object.keys(this.participantTracker).length == 0) {
                // if there are no participants tracked. this is the first and therefore the "parent".

                /* TODO:
                 * Revisit this.
                 * If a participant from a group logs in on their own, they might get marked as parent in future.
                 */
                this.context.commit('UPDATE_PARTICIPANT_TRACKER', { uuid: participant_uuid, line_items: line_items, name: participant_name, parent: true, discount_total: this.fees.discount_total, subtotal: this.fees.subtotal, balance: this.fees.balance });
            } else {
                // otherwise they are not the parent... obviously.
                this.context.commit('UPDATE_PARTICIPANT_TRACKER', { uuid: participant_uuid, line_items: line_items, name: participant_name, parent: false, discount_total: this.fees.discount_total, subtotal: this.fees.subtotal, balance: this.fees.balance });
            }
        }

        // Finally, with whatever data we have in store. Update the reg totals.
        try {
            await this.context.dispatch('updateTotal')
        } catch (error) {
            Container.get(ErrorHandlerService).error(error);
        }
        return response
    }

    @Action({ rawError: true })
    async updateTotal(): Promise<void> {
        // get all line items from all participants.
        let lineItems: Record<string, any>[] = [];
        for(const uuid in this.participantTracker) {
            lineItems = [...lineItems, ...this.participantTracker[uuid].lineItems]
        }
        // take all line items and reduce pricing down to a single object.
        const totals = (lineItems as any).reduce((previousValue: any, currentValue: any) => {
            return {
                subtotal: (currentValue.subtotal ?? 0) + (previousValue.subtotal ?? 0),
                tax: (currentValue.tax ?? 0) + (previousValue.tax ?? 0),
                total_discounted:  (currentValue.total_discounted ?? 0) + (previousValue.total_discounted ?? 0),
                total: (currentValue.total ?? 0) + (previousValue.total ?? 0)
            }
        }, { subtotal: 0, tax: 0, total_discounted: 0, total: 0 });

        // finally commit the totals to the store.
        this.context.commit('UPDATE_REG_TOTALS', totals);
    }

    @Action({ rawError: true })
    async getGroupFees (payload: any) {
        const  { event_identifier, group_uuid } = payload
        const response = await Container.get(PaymentService).getGroupFees(event_identifier, group_uuid)
        this.context.commit('SET_GROUP_FEES', response.data)
        return response
    }

    @Action({ rawError: true })
    async makePayment () {
        // TODO
    }
}
